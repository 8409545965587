<template>
  <va-card title="Form submits">
    <va-data-table
      :fields="fields"
      :data="items"
      :per-page="20"
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="toggle" slot-scope="props">
        <va-toggle
          small
          v-on:input="togglePublished(props.rowData.id)"
          v-model="props.rowData.published"
        />
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          :to="{ name: '1lk-form-submits-view', params: { id: props.rowData.id } }"
          small
          color="info"
        >
          Переглянути
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [
        {
          name: 'created_at',
          title: 'Created at',
          width: '15%',
        },
        {
          name: 'name',
          title: 'Name',
          width: '15%',
        },
        {
          name: 'mail',
          title: 'email',
          width: '15%',
        },
        {
          name: 'phone',
          title: 'Phone',
          width: '15%',
        },
        {
          name: 'comments',
          title: 'Comments',
          width: '15%',
        },
        {
          name: 'source',
          title: 'Source',
          width: '15%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    togglePublished (id) {
      axios.put(`${process.env.VUE_APP_1LK_API_URL}/admin/front-form/${id}?lang=${this.locale}`, {
        published: this.items.find((item) => item.id === id).published ? 1 : 0,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/front-form?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.forEach((item) => {
            item.published = !!item.published
            const date = new Date(item.created_at * 1000)
            item.created_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
